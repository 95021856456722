import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import GlobalStyle from "./ui/assets/css/global-css";
import ProtectedRoute from "./components/core/routes/ProtectedRoute";

import AnimatedSwitch from "./components/core/routes/AnimatedSwitch";
import IndexView from "./components/views/index/index.view";
import ConnexionView from "./components/views/utilisateur/connexion/connexion.view";
import DeconnexionView from "./components/views/utilisateur/deconnexion/deconnexion.view";
import Error404View from "./components/views/error404/error404.view";

import "semantic-ui-css/semantic.min.css";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "./ui/assets/css/ag-datagrid.css";

// const { theme } = require(`./ui/ads.theme.ts`); // ADS
// const { theme } = require(`./ui/santelys.theme.ts`); // SANTELYS
// const { theme } = require(`./ui/adene.theme.ts`); // ADENE
// const { theme } = require(`./ui/agir.theme.ts`); // AGIR
// const { theme } = require(`./ui/airpartenairesante.theme.ts`); // AIR PARTENAIRE SANTE
// const { theme } = require(`./ui/airra.theme.ts`); // AIRRA
const { theme } = require(`./ui/arard.theme.ts`); // ARARD
// const { theme } = require(`./ui/alair.theme.ts`); // ALAIR

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AnimatedSwitch>
          <ProtectedRoute path="/" exact component={IndexView} />
          <Route path="/utilisateur/connexion" component={ConnexionView} />
          <Route path="/utilisateur/deconnexion" component={DeconnexionView} />
          <ProtectedRoute path="/error-404" component={Error404View} />

          <Route path="*">
            <Redirect to="/error-404" />
          </Route>
        </AnimatedSwitch>
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
