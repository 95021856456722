import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { AnimatePresence, motion } from "framer-motion";

import {
  selectConnecte,
  selectUtilisateur,
} from "../../../../../redux/utilisateur/utilisateur.slice";

import { Button } from "../../button/button.elements";

// import logoSrc from "../../../../../ui/assets/img/logo-ads.png"; // ADS
// import logoSrc from "../../../../../ui/assets/img/logo-agir.png"; // AGIR
// import logoSrc from "../../../../../ui/assets/img/logo-adene-blanc.png"; // ADENE
// import logoSrc from "../../../../../ui/assets/img/logo-santelys.png"; // SANTELYS
// import logoSrc from "../../../../../ui/assets/img/logo-airpartenairesante.png"; // AIRCAEN
// import logoSrc from "../../../../../ui/assets/img/airra-logo.png"; // AIRRA
import logoSrc from "../../../../../ui/assets/img/arard-logo.png"; // ARARD
// import logoSrc from "../../../../../ui/assets/img/alair-logo.png"; // ALAIR

import { Navigation } from "../../navigation/navigation.desktop.elements";

const Logo = styled.img`
  margin: 0 0 0 10px;
  height: 40px;
  width: auto;
`;

const AppTopBar = styled.div`
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  /*
  background: ${(props) =>
    props.theme.colors.primary
      .main}; // Fond couleur pleine pour logos blancs ex: ADENE, ADS
  */
  background: linear-gradient(
    45deg,
    #fff 340px,
    ${(props) => props.theme.colors.primary.main}
  );
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.border.light};
`;

const User = styled.div`
  padding: 10px 20px;
  color: #fff;
`;

const UserName = styled.span`
  margin: 0 10px 0 0;
  padding: 10px 0;
  cursor: default;
`;

const PageTitleContainer = styled.div`
  flex-grow: 1;
  margin-left: 45vw;
`;

const PageTitle = styled.div`
  font-size: 18px;
  cursor: default;
`;

const BouttonNavigation = styled(Button)`
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.button.primary};
  background: transparent;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.neutral.main};
    background: transparent;
  }
`;

const ButtonSignOut = styled(Button)`
  font-size: 22px;
  background: ${(props) => props.theme.colors.transparent};
`;

const NavigationContainer = styled.div``;

const TopBar = (): JSX.Element | null => {
  const location = useLocation();
  const bConnecte = useSelector(selectConnecte);
  const utilisateur = useSelector(selectUtilisateur);
  const [titleKey, setTitleKey] = useState<string>("");
  const [bNavigation, setNavigation] = useState<boolean>(false);

  const hideNavigation = (): void => {
    setNavigation(false);
  };

  useLayoutEffect(() => {
    setTitleKey(location.pathname);
  }, [location.pathname]);

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0, display: "none" },
  };

  if (!bConnecte) {
    return null;
  }

  return (
    <AppTopBar>
      <Logo src={logoSrc} alt="" />
      <PageTitleContainer>
        <AnimatePresence>
          {location.pathname !== "/" && (
            <motion.div
              // when titleKey changes our title will re-render and trigger fade in/out
              key={titleKey || ""}
              initial="initial"
              animate="enter"
              exit="exit"
              variants={variants}
              style={{
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <PageTitle>{document.title.split(" |")[0]}</PageTitle>
            </motion.div>
          )}
        </AnimatePresence>
      </PageTitleContainer>
      <NavigationContainer>
        {["/", "/utilisateur/connexion", "/utilisateur/deconnexion"].indexOf(
          location.pathname
        ) === -1 && (
          <BouttonNavigation
            onClick={(evt) => {
              evt.preventDefault();
              setNavigation(!bNavigation);
            }}
          >
            {bNavigation ? <span>✕</span> : <Icon name="bars" />}
          </BouttonNavigation>
        )}
      </NavigationContainer>
      <User>
        <Icon name="user" />
        <UserName>{`${utilisateur.sCodeCivilite || ""} ${utilisateur.sPrenom} ${
          utilisateur.sNom
        }`}</UserName>

        <Link to="/utilisateur/deconnexion">
          <ButtonSignOut type="button" title="Déconnexion">
            <Icon name="power off" />
          </ButtonSignOut>
        </Link>
      </User>
      <Navigation show={bNavigation} hideNavigation={hideNavigation} />
    </AppTopBar>
  );
};

export default TopBar;
